import { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar({page})
{
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    return(
        <div className="navbar-area fixed-top">
            {/* Menu For Mobile Device  */}
            <div className="mobile-nav mean-container">
                <div className="mean-bar">
                    <a
                    className="meanmenu-reveal" 
                    onClick={toggleNav} 
                    style={{ right: '0px', left: 'auto', textAlign: 'center', textIndent: '0px', fontSize: '18px' }}
                    >
                    <span><span><span></span></span></span>
                    </a>
                    <nav className="mean-nav">
                        <ul className="navbar-nav" style={{ display: isNavOpen ? 'block' : 'none' }}>
                            <li className="nav-item">
                                <Link to="/" className={(page === "home")?"active":""}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className={(page === "about")?"active":""}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/menu">Beyond Sizzler</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/gallery" className={(page === "gallery")?"active":""}>Sizzlers</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className={(page === "contact")?"active":""}>Contact Us</Link>
                            </li>
                            <li className="nav-item mean-last">
                                <Link className="orderNow" to="https://letsoow.petpooja.com/">Order Now</Link>
                            </li>
                        </ul>
                        <div className="side-nav">
                            <a style={{textDecoration: 'none'}} className="nav-tel" href="tel:+918169161899">
                            <i className="bx bxs-phone-call"></i>
                            +91 81691 61899
                            </a>
                        </div>
                    </nav>
                </div>
                <a href="/" className="logo">
                    <img src="/assets/img/Logo.webp" style={{maxWidth: '25%'}} alt="Logo" />
                </a>
            </div>

            {/* Menu For Desktop Device  */}
            <div className="main-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link className="navbar-brand" to="/">
                            <img style={{maxWidth: '40%'}} src="/assets/img/Logo.webp" alt="Logo"/>
                        </Link>
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item"><Link to="/" className={(page === "home")?"active":""}>Home</Link></li>
                                <li className="nav-item"><Link to="/about" className={(page === "about")?"active":""}>About</Link></li>								
                                <li className="nav-item"><Link to="/gallery" className={(page === "gallery")?"active":""}>Sizzlers</Link></li>
                                <li className="nav-item"><Link to="/menu" className={(page === "menu")?"active":""}>Beyond Sizzler</Link></li>
                                <li className="nav-item"><Link to="/contact" className={(page === "contact")?"active":""}>Contact Us</Link></li>
                                <li className="nav-item"><Link className="orderNow" to="https://letsoow.petpooja.com/">Order Now</Link></li>
                            </ul>
                            <div className="side-nav">
                                <a style={{textDecoration: 'none'}} className="nav-tel" href="tel:+918169161899">
                                    <i className='bx bxs-phone-call'></i>
                                    +91 81691 61899
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}