import { useEffect } from "react";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';

export default function Index()
{
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const images = [
        "/assets/img/client-image/kitchen-1.webp",
        "/assets/img/client-image/kitchen-2.webp",
        "/assets/img/client-image/kitchen-3.webp",
        "/assets/img/client-image/kitchen-4.webp"
    ]

    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: true,
        autoplay: true,
        responsive: {
            0: { items: 1 },
            600: { items: 1 },
            1000: { items: 1 },
        },
    };
    return(
        <>
            {/* Navbar */}
            <Navbar page="home" />

            {/* Banner */}
            <Banner />

            {/* Video Section */}
            <div className="customContainer about-area-two pt-100 pb-70">
                <div className="about-shape">
                    {/* <img src="assets/img/client-image/float-new.png" className="rounded-circle" alt="Shape" /> */}
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <OwlCarousel className="service-slider owl-theme" {...options}>
                                    <img style={{width: '85%'}} src="/assets/img/client-image/index-1.jpg" alt="About"/>
                                    <img style={{width: '85%'}} src="/assets/img/client-image/index-2.jpg" alt="About"/>
                                    <img style={{width: '85%'}} src="/assets/img/client-image/index-3.jpg" alt="About"/>
                                </OwlCarousel>
                                <img src="/assets/img/home-three/about2.png" alt="About"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="section-title">
                                    <h1>Customise to your taste</h1>
                                    <h2>Pure Veg, Jain, Vegan & Gluten free Sizzlers.</h2>
                                    <p>
                                        At Our Sizzling Kitchen, we take pride in using only the finest and healthiest organic ingredients when preparing our delicious sizzlers. We customise to your taste, be it Jain sizzlers, Vegan or Gluten free or anything that you would like to match your palate.  Satisfy your vegetarian cravings with our mouthwatering veg sizzlers. Perfect for sharing or indulging solo. Choose from a variety of options to suit your taste.
                                    </p>
                                </div>
                                <Link style={{textDecoration: 'none'}} className="cmn-btn customBtn" to="/gallery">Know More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Services Section */}
            <section className="service-area-three pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="service-item">
                                <div className="section-title">
                                    <span className="sub-title">Welcome to Our</span>
                                    <h1 className="text-light">Sizzling Kitchen</h1>
                                    <p>
                                    Experience the explosion of flavors with our signature veg sizzler. Packed with fresh veggies, aromatic spices, and a sizzling hot presentation. Every Sizzler is crafted with genuine warmth, ensuring a delightful experience along with utmost hygiene that resonates long after the last bite.
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-6">
                                        <ol className="text-light">
                                            <li>
                                                <a>House Parties</a>
                                            </li>
                                            <li>
                                                <a>Corporate Lunches</a>
                                            </li>
                                            <li>
                                                <a>Anniversaries & Birthdays</a>
                                            </li>
                                            <li>
                                                <a>Gift a Meal Hamper</a>
                                            </li>
                                            <li>
                                                <a>Pure Veg & Jain Food</a>
                                            </li>
                                            <li>
                                                <a>And for all special moments</a>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-img">
                                <img style={{borderRadius: '25px'}} src="/assets/img/client-image/home-item-2.webp" alt="Service" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Youtube Section 1 */}
            <section className="customContainer service-area-three pt-100 pb-70" style={{background: 'transparent'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="service-img">
                                <div class="card profile-card" style={{
                                    marginTop: "20px",
                                    borderRadius: "28px",
                                    boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                }}>
                                    <iframe width="100%" height="315" style={{borderRadius:'28px'}} src="https://www.youtube.com/embed/ybE3pUM0o9g?si=jPVA83_py38MeI7o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-item">
                                <div className="section-title">
                                    <h1 style={{color: 'black'}}>Frequently Asked Questions</h1>
                                    <p style={{color: 'black'}}>
                                        In this video, the company answers FAQs about their service. They outline the easy plate return process and confirm they serve only pure vegetarian food, with Jain and vegan options. Viewers are encouraged to leave any further questions in the comments, and the company thanks them for their support.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Youtube Section 2 */}
            <section className="service-area-three pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="service-item">
                                <div className="section-title">
                                    <h1 className="text-light">Sizzler Unpacking Instructions</h1>
                                    <p>
                                        Gourment sizzlers are now available for you to enjoy at home. Simply unwrap the plate, heat it for 6 to 8 minutes, melt the butter over the veggies, and pour the sauce. Let it rest for 2 minutes, and your tasty sizzler is ready to enjoy!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-img">
                                <div class="card profile-card" style={{
                                    marginTop: "20px",
                                    borderRadius: "28px",
                                    boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                }}>
                                    <iframe width="100%" height="315" style={{borderRadius:'28px'}} src="https://www.youtube.com/embed/T9ndviPFknQ?si=NMBjdSJGVvu_gMLr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Youtube Section 3 */}
            <section className="customContainer service-area-three pt-100 pb-70" style={{background: 'transparent'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="service-img">
                                <div class="card profile-card" style={{
                                    marginTop: "20px",
                                    borderRadius: "28px",
                                    boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                }}>
                                    <iframe width="100%" height="315" style={{borderRadius:'28px'}} src="https://www.youtube.com/embed/ZQJW0sHX5HI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-item">
                                <div className="section-title">
                                    <h1 style={{color: 'black'}}>Corporate Lunch & Dinner Party Idea</h1>
                                    <p style={{color: 'black'}}>
                                        Hosting a sizzling boardroom lunches & parties is now much easier than you can imagine. We provide serving staff with induction while you just sit back & enjoy the meal.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Disclaimer Area */}
            <section class="reservation-area">
                <div class="reservation-shape">
                    <img src="assets/img/home-one/reservation-shape.png" alt="Shape"/>
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <div class="reservation-item" style={{paddingBottom: '20px', paddingTop: '50px'}}>
                                <div class="section-title">
                                    {/* <h2>Health Disclaimer</h2>
                                    <p>
                                        Let's Sizzle It! is a very health & hygiene conscious kitchen. 
                                        Sourcing fresh organic veggies on daily basis, ensuring utmost freshness and controlling 
                                        the quality of food is our top priority. Our dishes are free from MSG (Ajinomoto),or any 
                                        added preservatives, and we adhere to all required food safety standards.
                                    </p> */}
                                    <img src="/assets/img/client-image/disclaimer/health.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="reservation-item" style={{paddingBottom: '70px'}}>
                                <div class="section-title">
                                    {/* <img style={{borderRadius: '18px'}} src="/assets/img/client-image/delivery.png" alt="" /> */}
                                    <img src="/assets/img/client-image/disclaimer/logistics.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="reservation-item" style={{paddingBottom: '20px', paddingTop: '50px'}}>
                                <div class="section-title">
                                    {/* <h2>Bag & Return Policy</h2>
                                    <p>
                                        The entire box packing along with iron/wooden plates will 
                                        have to be returned back to us & will be picked up the next day by wefast/ porter / uber.
                                    </p> */}
                                    <img src="/assets/img/client-image/disclaimer/disclaimer.jpg" alt="" />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Collection */}
            <section className="customContainer collection-area collection-area-two ptb-100">
                <div className="container">
                    <div className="section-title">
                        {/* <span className="sub-title">Restaurant Name</span> */}
                        <h1>Our Kitchen</h1>
                    </div>

                    <div id="Container" className="row">
                        {
                            images.map(img => <div className="col-sm-6 col-lg-3 mix web ui">
                                <div className="collection-item">
                                    <div className="collection-top">
                                        <img src={img} alt="Collection" />
                                        <ul>
                                            <li>
                                                <i className='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i className='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i className='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i className='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i className='bx bxs-star checked'></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>

                    <div className="more-collection">
                        <Link style={{textDecoration: 'none'}} className="orderNow" to="https://letsoow.petpooja.com/">Order Online Now</Link>
                    </div>
                </div>
            </section>

            {/* Story */}
            <section class="reservation-area">
                <div class="reservation-shape">
                    <img src="assets/img/home-one/reservation-shape.png" alt="Shape"/>
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="reservation-item">
                                <div class="section-title">
                                    <h1 className="text-light">Our Story</h1>
                                    <p>
                                        Welcome to Let's Sizzle It! - where culinary magic meets entrepreneurial flair, all thanks to the dynamic duo behind the scenes - Namita and Hemal Khambatta. 
                                    </p>
                                    <br />
                                    <p>
                                        <Link to="/about" className="btn btn-warning">Know More</Link>
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="reservation-img">
                                <img src="assets/img/client-image/cut-out.png" alt="Reservation"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Chef Area */}
            <section class="chef-area chef-area-two">
                <div class="container">
                    <div class="section-title section-title1">
                        <h2>SERVING BEST SIZZLERS IN MUMBAI<br />
                            Delivered to your Doorsteps</h2>
                            <a href="#" target="_blank"><img src="https://templates.petpooja.com/letssizzle/assets/img/zom-002.png" alt=""/></a>
                            <a href="#" target="_blank"><img src="https://templates.petpooja.com/letssizzle/assets/img/swg-002.png" alt=""/></a>
                            {/* <a href="#" target="_blank"><img src="./assets/img/swg-002.png" alt=""/></a> */}
                    </div> 
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </>
    )
}